import { createSlice, PayloadAction } from '@reduxjs/toolkit';

let initialLang = 'cs';

const languageSlice = createSlice({
  name: 'language',
  initialState: initialLang,
  reducers: {
    setAppLanguage: (state, action: PayloadAction<string>) => {
      return action.payload ? action.payload : state
    },
  },
});

export const { setAppLanguage } = languageSlice.actions;
export default languageSlice.reducer;
